import { createSlice, current, original } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '..';
import type { TenderItemSupplierTypes } from '../../types';

const initialState = {
	addedSuppliersForMultiselection: [],
	currentState: [],
	previousState: [],
	temporarySuppliersForAdding: {},
	temporarySuppliersForDeletion: []
} as {
	currentState: {
		[key: string]: {
			[key: string]: TenderItemSupplierTypes;
		};
	}[];
	// @ts-ignore
	temporarySuppliersForDeletion: { supplierId: string; tenderId: string }[];
	previousState: {
		[key: string]: TenderItemSupplierTypes;
	}[];
	addedSuppliersForMultiselection: string[];
	temporarySuppliersForAdding: {
		[key: string]: any[];
	};
};
const tenderSuppliers = createSlice({
	initialState,
	name: 'tenderSuppliers',
	reducers: {
		cancelSetSuppliers(state: any) {
			// eslint-disable-next-line no-param-reassign
			state.currentState = state.previousState;
			// eslint-disable-next-line no-param-reassign
			state.temporarySuppliersForAdding = {};
			// eslint-disable-next-line no-param-reassign
			state.temporarySuppliersForDeletion = [];
		},
		deleteSupplier(state: any, action: PayloadAction<{ supplierId: string; tenderId: string }>) {
			const { supplierId, tenderId } = action.payload;
			if (supplierId.includes('-')) {
				state.temporarySuppliersForDeletion.push({ supplierId, tenderId });
			}
		},
		removeAllSuppliers(state: any, action: PayloadAction<{ tenderItems: string[] }>) {
			const { tenderItems } = action.payload;
			tenderItems.forEach((tid: string) => {
				const stateSuppliers = current(state.currentState[tid]);
				// eslint-disable-next-line no-param-reassign
				state.currentState[tid] = stateSuppliers.filter((supplier: any) => !supplier.addedByUser);
			});
		},
		saveSuppliers(state: any) {
			// eslint-disable-next-line no-param-reassign
			state.previousState = state.currentState;
			// eslint-disable-next-line no-param-reassign
			state.temporarySuppliersForAdding = {};
			// eslint-disable-next-line no-param-reassign
			state.temporarySuppliersForDeletion = [];
		},
		setSuppliers(state: any, action: PayloadAction<{ [key: string]: any }>) {
			const { payload } = action;
			const structure = {};
			Object.keys(payload).forEach((key: string) => {
				// @ts-ignore
				structure[key] = payload[key].userItemRefinementSuppliers;
			});
			// eslint-disable-next-line no-param-reassign
			state.previousState = structure;
			// eslint-disable-next-line no-param-reassign
			state.currentState = structure;
			// eslint-disable-next-line no-param-reassign
			state.temporaryIdsForDeletion = [];
		},
		updateSuppliers(
			state: any,
			action: PayloadAction<{ userItemRefinementSuppliers: any[]; tenderId: string }>
		) {
			const { userItemRefinementSuppliers, tenderId } = action.payload;
			// eslint-disable-next-line no-param-reassign
			state.currentState[tenderId] = userItemRefinementSuppliers;
		},
		updateSuppliersInMultiselectedTenderItems(
			state: any,
			action: PayloadAction<{
				tenderItems: string[];
				supplier: TenderItemSupplierTypes;
				type: 'ADD' | 'REMOVE';
			}>
		) {
			const { tenderItems, supplier, type } = action.payload;
			if (type === 'ADD') {
				tenderItems.forEach((tid: string) => {
					const suppliers = original(state.currentState[tid]);

					state.currentState[tid].push(supplier);
					state.addedSuppliersForMultiselection.push(supplier);
					// eslint-disable-next-line no-param-reassign
					state.temporarySuppliersForAdding[tid] = [...suppliers, supplier];
				});
			}
			if (type === 'REMOVE') {
				tenderItems.forEach((tid: string) => {
					const suppliers = original(state.currentState[tid]);
					const newSuppliers = suppliers.filter((supp: any) => supp.id !== supplier.id);
					// eslint-disable-next-line no-param-reassign
					state.currentState[tid] = newSuppliers;
					// eslint-disable-next-line no-param-reassign
					state.temporarySuppliersForAdding[tid] = newSuppliers;
				});
			}
		},
		updateSuppliersInTenderItem(state: any, action: PayloadAction<any>) {
			const { selectorId, suppliers } = action.payload;
			const suppliersWithListOrderIndex = suppliers.map(
				(supplier: TenderItemSupplierTypes, index: number) => ({
					...supplier,
					listOrderIndex: index
				})
			);
			const previousSuppliers = original(state.previousState[selectorId]);
			const newSuppliers = suppliersWithListOrderIndex.filter(
				(supplier: any) => !previousSuppliers.some((supp: any) => supp.id === supplier.id)
			);
			// eslint-disable-next-line no-param-reassign
			state.temporarySuppliersForAdding[selectorId] = newSuppliers;
			// eslint-disable-next-line no-param-reassign
			state.currentState[selectorId] = suppliersWithListOrderIndex;
		}
	}
});

export default tenderSuppliers.reducer;
export const {
	cancelSetSuppliers,
	setSuppliers,
	updateSuppliersInTenderItem,
	updateSuppliersInMultiselectedTenderItems,
	removeAllSuppliers,
	deleteSupplier,
	saveSuppliers,
	updateSuppliers
} = tenderSuppliers.actions;

export const selectSuppliersOfTenderItems = (state: RootState) =>
	state.tenderSuppliers.currentState;

export const selectSuppliersOfTenderItem = (tid: string) => (state: RootState) => {
	const { currentState } = state.tenderSuppliers;
	// @ts-ignore
	return currentState[tid];
};

export const selectAddedSupplieForMultiselection = (state: RootState) =>
	state.tenderSuppliers.addedSuppliersForMultiselection;

export const selectTemporarySuppliersForAdding = (state: RootState) =>
	state.tenderSuppliers.temporarySuppliersForAdding;
export const selectTemporarySuppliersForDeletion = (state: RootState) =>
	state.tenderSuppliers.temporarySuppliersForDeletion;
