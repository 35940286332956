import { createApi } from '@reduxjs/toolkit/query/react';

import type {
	PollingDataType,
	TenderDocContentResponseType,
	TenderDocumentHeaderContentType
} from '../../types/TenderDocument.types';
import type {
	Award,
	BoQ,
	BoQBody,
	BoqCtgiesData,
	BoQCtgy,
	GaebType,
	Item
} from '../../types/TenderDocumentType.types';
import { aiPredictionsApi } from '../aiPredictions/aiPredictionsSlice';
import { baseQuery, TENDER_DOC_URL, TENDER_MANAGEMENT_URL } from '../stateUtils';
import { setTenderItems, updateTenderItemsData } from '../tenderItems/tenderItemsState';
import { setSuppliers } from '../tenderItems/tenderItemsSuppliersState';
import { createInitialTreeStructure, mapOverItems } from '../tenderItems/treeDataUtils';
import { toggleShouldOpenBlockerModal } from '../ui/uiSlice';
import { userRefinement2Api } from '../userRefinement/userRefinement2Slice';

const tenderDocContentApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			deleteTenderDocContentAttachment: builder.query<string, string>({
				query(id) {
					return {
						method: 'DELETE',
						url: `${TENDER_MANAGEMENT_URL}document-responses/${id}`
					};
				}
			}),
			fetchAward: builder.query<Award, string>({
				query: (id) => ({
					url: `${TENDER_DOC_URL}awards/${id}`
				})
			}),
			fetchBoQ: builder.query<BoQ, string>({
				query: (id) => ({
					url: `${TENDER_DOC_URL}bill-of-quantities/${id}`
				})
			}),
			fetchBoQBodies: builder.query<BoQBody, string>({
				query: (id) => ({
					url: `${TENDER_DOC_URL}bo-q-bodies/${id}`
				})
			}),
			fetchBoQCtgy: builder.query<BoqCtgiesData, string>({
				query: (id) => ({
					url: `${TENDER_DOC_URL}bo-q-ctgies/${id}`
				})
			}),
			fetchGaeb: builder.query<GaebType, { contentUUID: string; sgArea: string }>({
				query: ({ contentUUID }) => ({
					url: `${TENDER_DOC_URL}gaebs/${contentUUID}`
				})
			}),
			fetchGaeb2: builder.query<GaebType, { contentUUID: string }>({
				query: ({ contentUUID }) => ({
					url: `${TENDER_DOC_URL}gaebs/${contentUUID}`
				})
			}),
			fetchItems: builder.query<any, string>({
				query: (tid) => ({
					url: `${TENDER_DOC_URL}itemlists/${tid}`
				})
			}),
			fetchTemplate: builder.query<string, string | null>({
				query: (id) => ({
					responseHandler: (response: any) => response.text(),
					url: `${TENDER_DOC_URL}gaebs/freemarker/${id}/default`
				})
			}),
			fetchTenderDocumentHeaderContent: builder.query<
				TenderDocumentHeaderContentType,
				string | null
			>({
				query: (id) => `${TENDER_DOC_URL}gaebs/header/data/${id}`
			}),
			fetchTenderGroups: builder.query<
				any,
				{
					docRefinementId: string;
					predictionId: string;
					id: string;
					contentUUID: string;
					totalItems: number;
				}
			>({
				async queryFn({ docRefinementId, predictionId, id, contentUUID, totalItems }, _queryApi) {
					const awardsResponse = await _queryApi.dispatch(
						tenderDocContentApi.endpoints.fetchAward.initiate(id)
					);
					const boqResponse = await _queryApi.dispatch(
						tenderDocContentApi.endpoints.fetchBoQ.initiate(awardsResponse.data?.boQ.tid as string)
					);
					const boqBodyResponse = await _queryApi.dispatch(
						tenderDocContentApi.endpoints.fetchBoQBodies.initiate(
							boqResponse.data?.boQBody.tid as string
						)
					);

					if (boqBodyResponse.error) {
						throw new Error();
					}
					const { boQCtgies } = boqBodyResponse.data as BoQBody;

					const response = await Promise.all(
						boQCtgies.map((ctg) =>
							_queryApi.dispatch(
								tenderDocContentApi.endpoints.fetchTenders.initiate({
									ctg,
									docRefinementId,
									position: ctg.rNoPart,
									predictionId
								})
							)
						)
					);

					const isError = response.some((res) => res.status === 'rejected');

					if (isError) {
						throw new Error();
					}

					const normalizedItems: { entities: { [key: string]: any }; ids: string[] } = {
						entities: {},
						ids: []
					};

					const tenders = response.map((res) => res.data);
					tenders
						.sort((a, b) => Number(a.position) - Number(b.position))
						.forEach((data) => {
							normalizedItems.ids.push(data.tid);
							normalizedItems.entities[data.tid] = data;
						});

					const { treeStructure, parentlessIds } = createInitialTreeStructure(normalizedItems);
					const normalizedData = {
						[contentUUID]: {
							data: treeStructure,
							fetchingStatus: 'LOADING',
							parentlessIds,
							status: 'DONE',
							totalCheckedItems: 0,
							totalItems,
							totalUncheckedItems: 0,
							treeData: parentlessIds?.length ? mapOverItems(treeStructure, parentlessIds) : []
						}
					};
					_queryApi.dispatch(setTenderItems(normalizedData as any));
					return { data: normalizedData };
				}
			}),
			fetchTenderPosition: builder.query<
				any,
				{
					itemsId: string;
					docRefinementId: string;
					predictionId: string;
					parent: string;
					position: string;
				}
			>({
				async queryFn({ itemsId, docRefinementId, predictionId, parent, position }, _queryApi) {
					const response = (await _queryApi.dispatch(
						tenderDocContentApi.endpoints.fetchItems.initiate(itemsId)
					)) as any;

					const isError = response.status === 'rejected';
					if (isError) {
						throw new Error();
					}
					const response2 = await Promise.all(
						response.data.items.map(async (item: Item) =>
							_queryApi.dispatch(
								tenderDocContentApi.endpoints.fetchTenderPositionData.initiate({
									docRefinementId,
									item,
									parent,
									position,
									predictionId
								})
							)
						)
					);
					const responsesData = response2
						.map((res) => res.data)
						.sort((a: any, b: any) => {
							if (a.listOrderIndex < b.listOrderIndex) return -1;
							if (a.listOrderIndex > b.listOrderIndex) return 1;
							return 0;
						})
						.map((data, index) => ({
							...data,
							position: `${data?.position}.${index + 1}`
						}));
					return { data: { position: responsesData } };
				}
			}),
			fetchTenderPositionData: builder.query<
				any,
				{
					item: Item;
					docRefinementId: string;
					predictionId: string;
					parent: string;
					position: string;
				}
			>({
				async queryFn({ item, docRefinementId, predictionId, parent, position }, _queryApi) {
					const itemPredictionResponse = await _queryApi.dispatch(
						aiPredictionsApi.endpoints.fetchAIPredictionsForItems.initiate({
							predictionId,
							tenderId: item.tid
						})
					);
					if (itemPredictionResponse.data) {
						const { data: itemPrediction } = itemPredictionResponse;

						let productCategory = '';
						if (itemPrediction.productCategoryPredictions.length) {
							const productCategoryResponse = await _queryApi.dispatch(
								aiPredictionsApi.endpoints.fetchAIProductCategoryPrediction.initiate(
									itemPrediction.productCategoryPredictions[0].id
								)
							);
							productCategory = productCategoryResponse?.data?.categoryName;
						}

						const userRefnementResponse = await _queryApi.dispatch(
							userRefinement2Api.endpoints.fetchItemRefinementsFromDoc.initiate({
								docRefinementId,
								tenderId: itemPrediction.tenderItemId
							})
						);

						if (userRefnementResponse.data) {
							const { data: userRefinementData } = userRefnementResponse;
							const { supplierAssigments, productAssigments } = userRefinementData;
							let newSuppliers: any[] = [];
							let newProducts: any[] = [];
							if (supplierAssigments.length) {
								const supplierAssigmentsResponse = await Promise.all(
									supplierAssigments?.map(async (supplierAssigment: any) =>
										_queryApi.dispatch(
											userRefinement2Api.endpoints.fetchSupplierAssigment.initiate(
												supplierAssigment.tid
											)
										)
									)
								);
								newSuppliers = supplierAssigmentsResponse
									.map(({ data }) => ({
										addedByUser: data.valueSource !== 'AI',
										id: data.tid,
										label: data.supplierName,
										probability: 1,
										supplierId: data.supplierId,
										tid: data.tid,
										value: data.supplierName
									}))
									.filter((supplier) => Boolean(supplier.label));
							}

							if (productAssigments.length) {
								const productAssigmentsResponse = await Promise.all(
									productAssigments?.map(async (productAssigment: any) =>
										_queryApi.dispatch(
											userRefinement2Api.endpoints.fetchProductAssigment.initiate(
												productAssigment.tid
											)
										)
									)
								);
								newProducts = productAssigmentsResponse.map((res) => res.data);
							}

							const tender = {
								isOffer: !!itemPrediction?.isOffer,
								isTenderGroup: false,
								itemPredictionId: itemPrediction.id,
								listOrderIndex: itemPrediction.listOrderIndex,
								longText: itemPrediction.longText,
								numOfEiwa: userRefinementData?.productAssigments.length,
								numOfHawa: 0,
								offer: false,
								parentItem: parent,
								parentUUID: parent,
								position,
								probability: 1,
								productAssigments: newProducts,
								productCategory,
								productPredictions: itemPrediction.productPredictions,
								quantity: item.quantity,
								refinementId: userRefinementData?.tid,
								shortText: itemPrediction.shortText,
								sorting: 0,
								status: userRefinementData?.status,
								tid: item.tid,
								unit: item.quantityUnit,
								userItemRefinementCategories: [],
								userItemRefinementSuppliers: newSuppliers,
								xmlId: item.xmlId
							};
							return { data: tender };
						}
					}
					return { data: {} };
				}
			}),
			fetchTenderPositions: builder.query<
				any,
				{ tenders: any; docRefinementId: string; predictionId: string; contentUUID: string }
			>({
				async queryFn({ tenders, docRefinementId, predictionId, contentUUID }, _queryApi) {
					const response = await Promise.all(
						tenders?.map(async (tender: any) =>
							_queryApi.dispatch(
								tenderDocContentApi.endpoints.fetchTenderPosition.initiate({
									docRefinementId,
									itemsId: tender.childBody.itemlist?.tid,
									parent: tender.tid,
									position: `${tender.position}`,
									predictionId
								})
							)
						)
					);

					const isError = response.some((res) => res.status === 'rejected');
					if (isError) {
						throw new Error();
					}

					const responsesData = response.map((res) => res.data);
					const positions = responsesData.map((data) => data.position).flat();
					const entities = positions.reduce((acc: any, obj: any) => {
						acc[obj.tid] = obj;

						return acc;
					}, {});
					_queryApi.dispatch(
						updateTenderItemsData({ contentUUID, entities, fetchingStatus: 'DONE' })
					);
					_queryApi.dispatch(setSuppliers(entities));
					return { data: { positions: entities } };
				}
			}),
			fetchTenders: builder.query<
				any,
				{
					ctg: BoQCtgy;
					predictionId: string;
					docRefinementId: string;
					position: string;
					parent?: any;
				}
			>({
				async queryFn({ ctg, predictionId, docRefinementId, position, parent }, _queryApi) {
					const boqCtgy = await _queryApi.dispatch(
						tenderDocContentApi.endpoints.fetchBoQCtgy.initiate(ctg.tid)
					);
					const itemPredictionResponse = await _queryApi.dispatch(
						aiPredictionsApi.endpoints.fetchAIPredictionsForItems.initiate({
							predictionId,
							tenderId: ctg.tid
						})
					);
					if (boqCtgy.data) {
						const childBody = await _queryApi.dispatch(
							tenderDocContentApi.endpoints.fetchBoQBodies.initiate(boqCtgy.data.childBoQBody.tid)
						);

						if (itemPredictionResponse.data) {
							const { data: itemPrediction } = itemPredictionResponse;
							let productCategory = '';
							if (itemPrediction.productCategoryPredictions.length) {
								const productCategoryResponse = await _queryApi.dispatch(
									aiPredictionsApi.endpoints.fetchAIProductCategoryPrediction.initiate(
										itemPrediction.productCategoryPredictions[0].id
									)
								);
								productCategory = productCategoryResponse?.data?.categoryName;
							}

							const userRefnementResponse = await _queryApi.dispatch(
								userRefinement2Api.endpoints.fetchItemRefinementsFromDoc.initiate({
									docRefinementId,
									tenderId: itemPrediction.tenderItemId
								})
							);
							if (userRefnementResponse?.data) {
								const { data: userRefinementData } = userRefnementResponse;

								const tender: any = {
									childBody: childBody.data,
									isOffer: itemPrediction.offer,
									isTenderGroup: true,
									itemPredictionId: itemPrediction.id,
									longText: itemPrediction.longText,
									numOfEiwa: 0,
									numOfHawa: 0,
									offer: false,
									parentItem: parent,
									parentUUID: parent,
									position,
									probability: 1,
									productCategory,
									quantity: 1,
									refinementId: userRefinementData.tid,
									shortText: ctg.lblTx,
									sorting: 0,
									status: userRefinementData.status,
									tid: ctg.tid,
									unit: '',
									userItemRefinementCategories: [],
									userItemRefinementSuppliers: [],
									userRefinementProducts: []
								};

								return { data: tender };
							}
						}
					}
					return { data: {} };
				}
			}),
			fetchTenderSubGroup: builder.query<
				any,
				{
					categories: BoQCtgy[];
					docRefinementId: string;
					predictionId: string;
					parent: string;
					position: string;
				}
			>({
				async queryFn({ categories, docRefinementId, predictionId, parent, position }, _queryApi) {
					const response = await Promise.all(
						categories.map(async (ctg, index) =>
							_queryApi.dispatch(
								tenderDocContentApi.endpoints.fetchTenders.initiate({
									ctg,
									docRefinementId,
									parent,
									position: `${position}.${index + 1}`,
									predictionId
								})
							)
						)
					);
					const isError = response.some((res) => res.status === 'rejected');
					if (isError) {
						throw new Error();
					}
					const subgroups: any = response.map((res) => res.data);
					return { data: { subgroups } };
				}
			}),
			fetchTenderSubGroups: builder.query<
				any,
				{ tenders: any; docRefinementId: string; predictionId: string; contentUUID: string }
			>({
				async queryFn({ tenders, docRefinementId, predictionId, contentUUID }, _queryApi) {
					const response = await Promise.all(
						tenders.map(async (tender: any) =>
							_queryApi.dispatch(
								tenderDocContentApi.endpoints.fetchTenderSubGroup.initiate({
									categories: tender.childBody.boQCtgies,
									docRefinementId,
									parent: tender.tid,
									position: tender.position,
									predictionId
								})
							)
						)
					);
					const isError = response.some((res) => res.status === 'rejected');
					if (isError) {
						throw new Error();
					}
					const responsesData = response.map((res) => res.data);
					const subgroups = responsesData.map((data) => data.subgroups).flat();
					const entities = subgroups.reduce((acc: any, obj: any) => {
						acc[obj.tid] = obj;

						return acc;
					}, {});

					_queryApi.dispatch(
						updateTenderItemsData({ contentUUID, entities, fetchingStatus: 'LOADING' })
					);

					return { data: { subgroups } };
				}
			}),

			pollingTenderDocStatus: builder.query<PollingDataType, string | null>({
				query: (id) => `${TENDER_MANAGEMENT_URL}document-responses/${id}`
			}),
			uploadTenderDocContent: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}file-upload`
					};
				}
			}),
			uploadTenderDocContentAttachments: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}attachment-upload`
					};
				}
			})
		};
	},
	reducerPath: 'tenderDocument'
});

export const {
	useFetchTenderDocumentHeaderContentQuery,
	useLazyFetchTemplateQuery,
	usePollingTenderDocStatusQuery,
	useUploadTenderDocContentMutation,
	useUploadTenderDocContentAttachmentsMutation,
	useLazyDeleteTenderDocContentAttachmentQuery,
	useFetchGaebQuery,
	useFetchAwardQuery,
	useFetchBoQQuery,
	useFetchBoQBodiesQuery,
	useFetchBoQCtgyQuery,
	useFetchTendersQuery,
	useFetchGaeb2Query,
	useFetchTenderGroupsQuery,
	useFetchTenderSubGroupsQuery,
	useFetchTenderPositionsQuery
} = tenderDocContentApi;

export const { select: selectTemplate } = tenderDocContentApi.endpoints.fetchTemplate;
export const selectTenders = (theQueryArg: any) =>
	tenderDocContentApi.endpoints.fetchTenderGroups.select(theQueryArg);

export { tenderDocContentApi };
