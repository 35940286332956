import { createSlice, original } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createInitialTreeStructure, mapOverItems } from './treeDataUtils';
import type { RootState } from '..';
import type { TenderItem } from '../../interfaces';
import getArePositionsChecked from '../tenderDocument/utils';

const initialState: {
	tenderItems: {
		[key: string]: {
			data: { [key: string]: any };
			parentlessIds: string[];
			treeData: TenderItem[];
			totalItems: number;
			totalCheckedItems: number;
			totalUncheckedItems: number;
			fetchingStatus: 'LOADING' | 'DONE';
		};
	};
} = {
	tenderItems: {}
};

const tenderItems = createSlice({
	initialState,
	name: 'tenderItems',
	reducers: {
		deleteProductTenderItem(
			state: any,
			action: PayloadAction<{
				entity: any;
				contentUUID: string;
			}>
		) {
			const { contentUUID, entity } = action.payload;
			const tenderItems = original(state.tenderItems[contentUUID]);
			const treeStructure = { ...tenderItems.data, [entity.tid]: entity };
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].treeData = mapOverItems(
				treeStructure,
				tenderItems.parentlessIds
			);
		},
		setTenderItems(state: any, action: PayloadAction<any>) {
			const tenderItemsState = original(state.tenderItems);
			// eslint-disable-next-line no-param-reassign
			state.tenderItems = { ...tenderItemsState, ...action.payload };
		},
		updateTenderItem(state: any, action: PayloadAction<{ updated: any; contentUUID: string }>) {
			const { updated, contentUUID } = action.payload;
			const tenderItems = original(state.tenderItems[contentUUID]);
			const treeStructure = {
				...tenderItems.data,
				[updated.tid]: updated
			};

			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].treeData = mapOverItems(
				treeStructure,
				tenderItems.parentlessIds
			);
		},
		updateTenderItemChildren(
			state: any,
			action: PayloadAction<{ entities: any; contentUUID: string; tenderId: string }>
		) {
			const { entities, contentUUID, tenderId } = action.payload;
			const tenderItems = original(state.tenderItems[contentUUID]);
			const tender = tenderItems.data[tenderId];
			const treeStructure = {
				...tenderItems.data,
				...entities,
				[tenderId]: { ...tender, children: Object.keys(entities) }
			};
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].treeData = mapOverItems(
				treeStructure,
				tenderItems.parentlessIds
			);
		},
		updateTenderItems(
			state: any,
			action: PayloadAction<{ treeStructure: { [key: string]: any } }>
		) {
			const { treeStructure } = action.payload;
			const tenderItems = original(state.tenderItems);
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.treeData = mapOverItems(treeStructure, tenderItems.parentlessIds);
		},
		updateTenderItemsData(
			state: any,
			action: PayloadAction<{
				entities: { [key: string]: any };
				fetchingStatus: 'LOADING' | 'DONE';
				contentUUID: string;
			}>
		) {
			const { entities, fetchingStatus, contentUUID } = action.payload;
			const tenderItems = original(state.tenderItems[contentUUID]);
			const newEntities = { ...tenderItems.data, ...entities };
			const { parentlessIds, treeStructure } = createInitialTreeStructure({
				entities: newEntities,
				ids: Object.keys(newEntities)
			});

			const { totalCheckedItems, totalUncheckedItems } = getArePositionsChecked({
				entities: { ...tenderItems.data, ...entities }
			});
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].treeData = mapOverItems(treeStructure, parentlessIds);
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].fetchingStatus = fetchingStatus;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].totalCheckedItems = totalCheckedItems;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems[contentUUID].totalUncheckedItems = totalUncheckedItems;
		}
	}
});

export default tenderItems.reducer;
export const {
	setTenderItems,
	updateTenderItem,
	updateTenderItems,
	updateTenderItemsData,
	deleteProductTenderItem,
	updateTenderItemChildren
} = tenderItems.actions;
export const selectTenderItems = (state: RootState, contentUUID: string) =>
	state.tenderItems.tenderItems[contentUUID];
export const selectTenderItem = (contentUUID: string, tid: string) => (state: RootState) => {
	const tenderItems = state.tenderItems.tenderItems[contentUUID];
	return tenderItems?.data[tid];
};
export const selectTenderItemProductPredictions =
	(contentUUID: string, tid: string) => (state: RootState) => {
		const { tenderItems: tenderItemsData } = state.tenderItems;
		const tenderItems = tenderItemsData[contentUUID];
		const ids: string[] = [];
		const entities = tenderItems?.data[tid]?.productPredictions?.reduce((acc: any, obj: any) => {
			ids.push(obj.id);
			acc[obj.id] = {
				...obj,
				isTenderGroup: false,
				parentItem: tid,
				product: true
			};
			return acc;
		}, {});

		return { entities, ids };
	};
