// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_INSIGHT_CONNECTION_STRING,
		// *** Add the Click Analytics plug-in. ***
		// extensions: [reactPlugin, clickPluginInstance],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory }
			// *** Add the Click Analytics plug-in. ***
			// [clickPluginInstance.identifier]: clickPluginConfig
		},
		// *** If you're adding the Click Analytics plug-in, delete the next line. ***
		extensions: [reactPlugin]
	}
});
appInsights.loadAppInsights();
