import { createApi } from '@reduxjs/toolkit/query/react';

import type { GenericObjectType } from '../../types';
import { setAiProducts } from '../search/searchAiPredictionsProducts2Slice';
import { baseQuery } from '../stateUtils';
import type { ProductProposal } from '../supplierProductProposal/supplierProductProposal';
import { supplierProductProposalApi } from '../supplierProductProposal/supplierProductProposal';
import { updateTenderItemChildren } from '../tenderItems/tenderItemsState';

const productMasterDataApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchHulkProductsByIds: builder.query<
				{ entities: { [key: string]: any }; ids: string[] },
				{
					ids: string;
					tid: string | undefined;
					isAiSearch?: boolean;
					contentUUID: string;
					docRefinementId?: string;
				}
			>({
				forceRefetch: () => true,
				async onQueryStarted(
					{ tid, isAiSearch, contentUUID, docRefinementId },
					{ queryFulfilled, getState, dispatch }
				) {
					try {
						const { data } = await queryFulfilled;
						// @ts-ignore
						const tenderItems = getState().tenderItems.tenderItems[contentUUID];
						const currentTenderItem = tenderItems.data[tid];
						const newChildren = data.ids.reduce((acc: any, id: any) => {
							const obj1 = data.entities[id];
							const matchingObj = currentTenderItem.productAssigments.find(
								(obj2: any) => obj2.productId === obj1.maraId
							);
							if (matchingObj) {
								acc.push({
									...obj1,
									...matchingObj,
									unit: obj1.unitOfMeasure
								});
							}
							return acc;
						}, []);
						if (docRefinementId) {
							const { data: supplierProductProposals } = await dispatch(
								supplierProductProposalApi.endpoints.fetchSupplierProductProposals.initiate(
									docRefinementId
								)
							);
							const ids = supplierProductProposals.productProposals.map(
								(productProposal: ProductProposal) => productProposal.tid
							);

							const { data: productProposals } = await dispatch(
								supplierProductProposalApi.endpoints.fetchProductProposalsByIds.initiate({ ids })
							);

							const filteredProductProposals = (
								productProposals as unknown as ProductProposal[]
							)?.filter(
								(product: ProductProposal) =>
									product.itemRefinementId === currentTenderItem.refinementId
							);
							const newChildrenWithProposals = newChildren.reduce((acc: any, child: any) => {
								const matchingObj = filteredProductProposals.find(
									(productProposal) => productProposal.productId === child.productId
								);
								if (matchingObj) {
									acc.push({
										...matchingObj,
										editableStatus: child.editableStatus,
										eiwaHawaType: child.eiwaHawaType,
										longText: matchingObj?.longText ? matchingObj.longText : child.longText,
										materialCategory: child.materialCategory,
										productAssigmentId: child.tid,
										shortText: matchingObj?.shortText ? matchingObj.shortText : child.shortText,
										unit: matchingObj.unit ? matchingObj.unit : child.unit
									});
								}
								return acc;
							}, []);
							const entities = newChildrenWithProposals.reduce((acc: any, obj: any) => {
								acc[obj.tid] = {
									...obj,
									isTenderGroup: false,
									parentItem: tid,
									priceType: obj.supplierPriceType,
									product: true,
									productCategory: obj.materialCategory,
									ttItemId: obj.productId
								};
								return acc;
							}, {});
							dispatch(
								updateTenderItemChildren({
									contentUUID,
									entities,
									tenderId: tid as string
								})
							);
						} else {
							dispatch(
								updateTenderItemChildren({
									contentUUID,
									entities: newChildren.reduce((acc: any, obj: any) => {
										acc[obj.tid] = {
											...obj,
											isTenderGroup: false,
											parentItem: tid,
											product: true,
											ttItemId: obj.maraId
										};
										return acc;
									}, {}),
									tenderId: tid as string
								})
							);
						}
						if (isAiSearch) {
							dispatch(setAiProducts({ data }));
						}
					} catch {
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: ({ ids }) => `services/productmasterdata/api/hulk-products-by-ids?ids=${ids}`,
				transformResponse: (response: any) =>
					response.reduce(
						(acc: any, curr: any) => {
							acc.entities[curr.id as string] = curr;
							if (!acc.ids.includes(curr.id)) {
								acc.ids.push(curr.id);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<any>,
							ids: [] as string[]
						}
					)
			})
		};
	},
	reducerPath: 'productMasterData'
});

export const { useFetchHulkProductsByIdsQuery } = productMasterDataApi;
export const selectHulkProducts = productMasterDataApi.endpoints.fetchHulkProductsByIds.select;

export { productMasterDataApi };
